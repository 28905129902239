import { Component } from '@angular/core';
import { Galeria } from './Model/galeria';
import { Modulo2Service } from './Service/modulo2.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  galeria = {} as Galeria;
  galerias = {} as Galeria[];

  title = 'JinstaladoraAngular';

  constructor(private galeriaService: Modulo2Service) {
    //this.galerias = [];
  }
  
  ngOnInit() {
    this.getGaleria();
  }

  getGaleria() {
    this.galeriaService.getGaleriaByIdCliente(8).subscribe(
      (res: Galeria[]) => {
        //console.log("Antes>>>>>>>>>>>" + res);  
      this.galerias = res;
      
      //console.log("Apos++++++++++++" + this.galerias);
      
    });
  }

  getUrlWts(nameImage: string){
    var dd = encodeURIComponent('https://syler.portalwepp.com/Loja/IMG/Modulo2/'+ nameImage)

    var ddd = 'https://api.whatsapp.com/send?phone=553492280250?text=Olá'
    + encodeURIComponent('https://syler.portalwepp.com/Loja/IMG/Modulo2/'+ nameImage);
    return ddd;
  }
}
